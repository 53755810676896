import useSWR from 'swr'
import { fetcherGraphQL } from '@/utils/fetch'
import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

function useSameTopic(categories: number | number[], postId: number) {
  const categoryIn =
    categories instanceof Array ? categories.join(',') : categories
  const PODCAST_CATEGORY = [521, 518]
  const { data, error } = useSWR(
    `
  {
    posts(where: {categoryIn: [${categoryIn}], categoryNotIn: [${PODCAST_CATEGORY.join(
      ','
    )}] notIn: ${postId}, orderby: {field: DATE, order: DESC}}, first: 3) {
      nodes {
        title
        slug
        date
        databaseId
      }
    }
  }
  `,
    fetcherGraphQL
  )
  return {
    data,
    isLoading: !error && !data,
    error,
  }
}

interface Post {
  title: string
  slug: string
  date: string
}

const SameTopic = ({ categories, postId }: any) => {
  const [posts, setPosts] = useState<Post[]>([])
  // get same topic
  const categoriesId = categories.map((category: any) => category.databaseId)
  const { data } = useSameTopic(categoriesId, postId)
  useEffect(() => {
    if (data) {
      setPosts(data.posts.nodes)
    } else {
      setPosts([])
    }
  }, [data])
  return (
    <div className="flex flex-col space-y-4 xl:p-4">
      <h4 className="heading-4">Cùng chủ đề</h4>
      <div className="flex flex-col space-y-6">
        <hr style={{ borderTop: '2px solid #3163FC' }} />
        {posts.map((post, index) => (
          <React.Fragment key={index}>
            <Link to={`/${post.slug}`}>
              <h5 className="transition heading-6 hover:text-blue-500">
                {post.title}
              </h5>
            </Link>
            <hr
              style={{
                borderTop: '2px solid #C4C4C4',
                opacity: index === posts.length - 1 ? 0 : 1,
              }}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default SameTopic
